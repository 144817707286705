import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/staff/pending',
	},
	post: {
		save: '/staff/pending',
		checkout: '/seats/purchase',
		seats: '/seats/request',
	},
	put: {},
	delete: {
		destroy: '/staff/pending/${id}'
	}
}

export const teamInvitationsApi = buildApi('staff', routes, '/manager')

