<template>
<div class='manager'>
	<div class='navPanel'>
		<ManagerNav />
	</div>
	<div class='mainPanel'>
		<transition name='slideNav'>
			<router-view />
		</transition>
	</div>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'	
import ManagerNav from '@/components/manager/ManagerNav'
//import CallToAction from '@/components/manager/CallToAction'

export default {
	name: 'Manager',
	mixins: [smallModalMixin],	
	components: { ManagerNav },
	methods: {
//		showCTA() { this.showSmallModal(CallToAction, null, '950px', '630px') }
	}
}
</script>

<style lang='scss'>
.manager {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;

	.navPanel {
		.inTrial {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 50px;
			background: $color-focus;
			font-size: $size-font-large;
			> span { margin: 0 $size-gutter; }
			> span:last-child {
				font-weight: bold;
				color: $color-primary-accent;
				text-decoration: underline;
			}
		}	
	}

	> .mainPanel {
		border-top: 1px solid $color-neutral-shadow;
		position: relative;
	}
}
</style>