import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/',
		subscriptionExists: '/subscription'
	},
	put: {
		save: '/',
		changePassword: '/password'
	}
}

export const profileApi = buildApi('profile', routes, '/manager/profile')

