<template>
<SmallModal class='addSeats' @close='$emit("close")'>
	<template v-slot:title>
		<template v-if='$store.state.profile.organization.trialDaysLeft'>Ready to Subscribe?</template>
		<template v-else>Change Number of Seats</template>
	</template>
	<template v-slot:message>
		<div v-if='!$store.state.profile.organization.trialDaysLeft' class='summary'>
			<div>
				<div><b>Total Seats</b></div>
				<div><b>Seats in Use</b></div>
			</div>
			<div>
				<div>{{$store.state.profile.organization.seats}}</div>
				<div>
					{{$store.state.profile.organization.activeStaff + teamInvitations}}<br />
					<span v-if='teamInvitations'>({{$store.state.profile.organization.activeStaff}} Staff, {{teamInvitations}} RSVP<template v-if='teamInvitations>1'>s</template>)</span>
				</div> 
			</div>
		</div>
		<template v-if='loaded'>
			<div class='howmany'>
				<template v-if='$store.state.profile.organization.trialDaysLeft'>Total Seats:</template>
				<template v-else>How many seats do you need?</template>
			</div>
			<div class='seatCounter'>
				<div class='less' @click='subSeat'>
					<svg class='smallIcon'>
						<use xlink:href='#minus' />
					</svg>
				</div>
				<div class='seats'>{{seats}}</div>
				<div class='more' @click='addSeat'>
					<svg class='smallIcon'>
						<use xlink:href='#plus' />
					</svg>
				</div>
			</div>
			<div class='newTotalSeats' :class='{ showSeats: $store.state.profile.organization.seats !== seats }'>New Total Seats: {{seats}}</div>		
			<template v-if='selfServe'>
				<div v-if='curr==="CAD"' class='cost'>
					{{seats}} x $9.80 / seat&nbsp;&nbsp;=&nbsp;&nbsp;<span>${{Number(9.8*seats).toFixed(2)}} / month</span>
				</div>
				<div v-else class='cost'>
					{{seats}} x $7.20 / seat&nbsp;&nbsp;=&nbsp;&nbsp;<span>${{Number(7.2*seats).toFixed(2)}} / month</span>
				</div>			
				<div v-if='!subscriptionExists' class='currency'>
					<div @click='curr="CAD"'>
						<CheckButton :checked='curr==="CAD"' />
						<div>CAD</div>
					</div>
					<div @click='curr="USD"'>
						<CheckButton :checked='curr==="USD"' />
						<div>USD</div>				
					</div>
				</div>
			</template>
			<RichText 
				v-else
				v-model='comments' 
				placeholder='Any additional comments?'
			/>
			<div class='questions'>Questions about this?  Contact <a href="mailto:sales@simpleset.net">sales@simpleset.net</a></div>
		</template>
		<transition name='fade'><Loading v-show='loading' /></transition>
	</template>
	<template v-slot:buttons>
		<div v-if='selfServe' class='iconTextButton success' @click='checkout'>
			<svg class='smallIcon'>
				<use xlink:href='#angleRight' />
			</svg>
			<span class='label'>Next</span>
		</div>
		<div v-else class='iconTextButton primary' @click='email'>
			<svg class='smallIcon'>
				<use xlink:href='#email' />
			</svg>
			<span class='label'>Request</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { teamInvitationsApi } from '@/services/api/modules/manager/teamInvitations'
import { profileApi } from '@/services/api/modules/manager/profile'
import { smallModalMixin } from '@/components/common/mixins/modal'
import SmallModal from '@/components/common/modals/SmallModal'
import AlertModal from '@/components/common/modals/Alert'
import Loading from '@/components/common/Loading'
import RichText from '@/components/common/RichText'
import TextInput from '@/components/common/TextInput'
import CheckButton from '@/components/common/buttons/Check'

export default {
	name: 'Feedback',
	mixins: [smallModalMixin],
	components: {
		SmallModal,
		RichText,
		TextInput,
		Loading,
		CheckButton
	},
	data() {
		return {
			comments: '', 
			seats: 2,
			teamInvitations: 0,
			subscriptionExists: false,
			loading: false,
			loaded: false,
			curr: 'USD'
		}
	},
	computed: {
		selfServe() { 
			return false
			//return this.$store.state.profile.organization.selfServe 
		}
	},
	methods: {
		async email() {
			if (this.loading) return
			this.loading = true
			const msg = `
				<b>Seats Requested: </b> ${this.seats}<br /><br />
				<b>Comments:</b><br />${this.comments}
			`
			await teamInvitationsApi.touch('seats', { msg })
			this.$emit('close')
			this.$store.dispatch('flash/showAction', 'thanks')
			this.showSmallModal(AlertModal, {
				title: 'Thanks for the info!',
				message: 'We\'ll be in touch with you shortly...'
			})
		},
		async checkout() {
			this.loading = true
			const payload = { seats: this.seats }
			if (!this.subscriptionExists) payload.curr = this.curr
			const [err, result] = await teamInvitationsApi.touch('checkout', payload)
			this.loading = false
			if (!err) {
				window.location.href = result.uri
			}
		},
		emailIfNotFocussed() {
			const elements = document.querySelectorAll( '.emailSetModal :focus' )
			if (!elements.length) this.email()
		},
		addSeat() {
			if (this.seats < 15 || !this.selfServe) this.seats++
		},
		subSeat() {
			if (this.seats > (this.$store.state.profile.organization.activeStaff+this.teamInvitations) && this.seats > 2) this.seats--
		}
	},
	async mounted() {
		this.loading = true
		await this.$store.dispatch('profile/fetch')
		const [errA, invitations] = await teamInvitationsApi.touch('fetch')
		this.teamInvitations = invitations.total
		if (this.selfServe) {
			const [errB, subscriptionExists] = await profileApi.touch('subscriptionExists')
			this.subscriptionExists = subscriptionExists.subscription
		}
		this.loading = false
		this.loaded = true
		if (this.$store.state.profile.organization.activeStaff || this.teamInvitations) this.seats = Math.max(this.$store.state.profile.organization.activeStaff + this.teamInvitations, 2)
	//	if (this.$store.state.profile.organization.seats > this.seats) this.seats = this.$store.state.profile.organization.seats
	}
}
</script>

<style lang='scss'>
.addSeats {
	.loading {
		background: rgba($color-white, .3);
	}
	p {
		font-size: $size-font-standard;
		line-height: 150%;
	}
	.field {
		margin-top: $size-gutter * 2;
	}

	.summary {
		margin-bottom: $size-gutter * 4;
		> div {
			display: flex;
			gap: 1px;
			
			&:first-child {
				font-size: $size-font-standard;
			}
			&:last-child {
				font-size: $size-font-large;
				font-weight: bold;
				color: $color-primary-accent;
			}
			> div { 
				flex: 1; 
				background: $color-neutral-accent; 
				height: $size-control-height; 
				display: flex;
				align-items: center;
				justify-content: center;
				> span { font-size: $size-font-small; margin-left: $size-gutter; margin-top: 4px; }
			}
		}
	}

	.howmany {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-large;
		margin: $size-gutter * 3;		
	}

	.seatCounter {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: $size-gutter * 4;
		.less, .more {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: $button-gradient-primary;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.seats {
			font-size: $size-font-large;
			font-weight: bold;
			color: $color-primary-accent;
			margin: 0 $size-gutter * 2; 
		}
	}

	.newTotalSeats {
		text-align: center;
		font-size: $size-font-large;
		font-weight: bold;
		color: $color-primary-accent;
		opacity: 0;
		&.showSeats { opacity: 1; }
	}

	.richText {
		border: 1px solid $color-neutral-shadow;
		margin-top: $size-gutter * 3;
	}

	.cost {
		font-size: $size-font-large;
		text-align: center;
		margin-top: $size-gutter * 2;
		span { font-weight: bold; color: $color-primary-accent; }
	}

	.currency {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-standard;
		margin: $size-gutter * 2 0;
		> div {
			display: flex;
			align-items: center;
			justify-content: center;	
			margin: 0 $size-gutter;		
			cursor: pointer;
		}
		.checkButton { margin-right: $size-gutter; pointer-events: none; cursor: pointer;}
	}

	.questions {
		font-size: $size-font-small;
		padding-top: $size-gutter * 3;
		margin-bottom: $size-gutter;
		text-align: center;
		a { color: $color-primary-accent; }
	}

}
</style>